<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="800px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">

      <el-form-item label="核验名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item label="随系统启动">
        <el-switch v-model="form.enabled" :active-value="1" />
      </el-form-item>

      <el-form-item label="核验周期">
        <el-popover v-model="cronPopover">
          <cron-input @change="e => form.cron = e" @close="cronPopover = false" />
          <el-input slot="reference" @click="cronPopover = true" v-model="form.cron"></el-input>
        </el-popover>
      </el-form-item>

      <el-form-item label="描述">
        <el-input v-model="form.description" type="textarea" resize="none" :rows="3" />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
import CronInput from '@/components/CronInput';
export default {
  components: { CronInput },
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: { cron: ''},

      cronPopover: false,
    }
  },

  methods: {
    show(initForm, options = {}) {
      this.form = _.assign({}, this.defaultForm, initForm, {});
      this.options = options;
      this.visible = true;
    },

    async confirmForm() {
      try {
        if(this.form.id) {
          await this.$api.execute('data_qa_check.update', this.form.id, _.omit(this.form, ['id']));
        } else {
          await this.$api.execute('data_qa_check.create', this.form);
        }
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        console.log({ e })
      }
    }
  }
}
</script>